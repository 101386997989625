@use 'constant.module.scss' as const;

.container {
  background: #fff;
  // margin: 0 auto;

  input {
    border: none;
    margin: 10px 25px;
    margin-right: 0;
    outline: none;
    width: 85%;
  }
}

.divider {
  width: 1px;
  background: const.$dark-gray;
  height: 100%;
}

.btn {
  height: 44px;
  width: 64px;
}

.lg {
  font-size: 1.7em;
}

.sm {
  font-size: 1em;
}

.md {
  font-size: 1.2em;
}

.hash {
  font-weight: 600;
}
